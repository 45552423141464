const environment = {
	api: {
		url: "https://api-preprod.ampmemberships.com",
	},
	localhost: {
		tenantKey: "",
	},
	aws: {
		url: "https://preprod-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	sentry: {
		dsn: "https://c02a13f116d645ceaeea9b8fa3f0d5fd@o559438.ingest.sentry.io/5697556",
	},
	google: {
		maps_api: "AIzaSyDRxkcF7tr8TNmkg9TE8R4yLaW6eM17X6c",
	}
};

export default environment;